import React from 'react';
import PropTypes from 'prop-types';
import { DIRECTION_STREET } from '../Input/Direction';

const GateTiltingResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const Palisade = () => (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY POD PALISADĘ RAMA</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>Otwór 1</b>
        {': '}
        {response.productionMaterials.palisadeHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.palisadeHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 2</b>
            {': '}
            {response.productionMaterials.palisadeHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.palisadeHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 3</b>
            {': '}
            {response.productionMaterials.palisadeHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.palisadeHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 4</b>
            {': '}
            {response.productionMaterials.palisadeHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.palisadeHoles.holeFive.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 5</b>
            {': '}
            {response.productionMaterials.palisadeHoles.holeFive.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.palisadeHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.palisadeHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.palisadeHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
      {values.height > 999 && (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>OTWORY POD PALISADĘ W DODATKOWYM POZIOMIE</th>
              </tr>
            </thead>
          </table>
          <p>
            <b>Otwór 1</b>
            {': '}
            {response.productionMaterials.railHoles.holeOne.size.value + 2.5}
            {' '}
            mm
            {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                {response.productionMaterials.railHoles.holeTwo.size.value + 2.5}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.railHoles.holeThree.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 3</b>
                {': '}
                {response.productionMaterials.railHoles.holeThree.size.value + 2.5}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.railHoles.holeFour.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>Otwór 4</b>
                {': '}
                {response.productionMaterials.railHoles.holeFour.size.value + 2.5}
                {' '}
                mm
              </>
            )}
            {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
              <>
                {' '}
                |
                {' '}
                <b>{response.productionMaterials.railHoles.lastHole.name}</b>
                {': '}
                {response.productionMaterials.railHoles.lastHole.size.value + 2.5}
                {' '}
                mm
              </>
            )}
          </p>
        </>
      )}
    </>
  );

  const railing = Object.keys(response.productionMaterials.railing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.railing[key].name}</td>
      <td>{response.productionMaterials.railing[key].material}</td>
      <td>
        {response.productionMaterials.railing[key].amount.value}
        {' '}
        {response.productionMaterials.railing[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.railing[key].size.value}
        {' '}
        {response.productionMaterials.railing[key].size.unit}
      </td>
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
      <td />
    </tr>
  ));

  const RailHoles = () => (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY POD SZTACHETĘ</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>Otwór 1</b>
        {': '}
        {response.productionMaterials.railHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 2</b>
            {': '}
            {response.productionMaterials.railHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 3</b>
            {': '}
            {response.productionMaterials.railHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>Otwór 4</b>
            {': '}
            {response.productionMaterials.railHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </>
  );

  let gallantry = null;
  if (values.width >= 6000) {
    gallantry = Object.keys(response.productionMaterials.gallantry).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.gallantry[key].name}</td>
        <td>{response.productionMaterials.gallantry[key].material}</td>
        <td>
          {response.productionMaterials.gallantry[key].amount.value}
          {' '}
          {response.productionMaterials.gallantry[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.gallantry[key].size.value}
          {' '}
          {response.productionMaterials.gallantry[key].size.unit}
        </td>
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
        <td />
      </tr>
    ));
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws" aria-label="extraColumn" />
                <th className="responseThD">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <Palisade />
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">SZTACHETY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">SZTACHETY</th>
                <th>{' '}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {values.direction === DIRECTION_STREET
            && <th colSpan={5}>UWAGA! - PRZYBLIŻONA ILOŚĆ SZTACHET I PRZYBLIŻONY ROZSTAW</th>}
          {railing}
        </tbody>
      </table>
      <RailHoles />
      {gallantry && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan="7">GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">GALANTERIA</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.gallantry[key].name}</td>
                <td>{response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].size.unit}
                </td>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

GateTiltingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateTiltingResponse;
