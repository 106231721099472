import React from 'react';
import PropTypes from 'prop-types';
import {
  FENCE_TYPE_BALLAST,
  FENCE_TYPE_WINDCHEST,
} from 'FenceTypesConstants';
import {
  MODEL_2D, MODEL_2DS, MODEL_3D_4, MODEL_3D_5, MODEL_ECO, MODEL_HIGHWAY, MODEL_P302, MODEL_P302_PB002, MODEL_P302_PS005,
  MODEL_PB001,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP001W,
  MODEL_PP002, MODEL_PP002DIVERSO, MODEL_PP002DIVERSO1, MODEL_PP002DIVERSO2, MODEL_PP002DIVERSO3,
  MODEL_PP002P, MODEL_PP002P102,
  MODEL_PP001P46,
  MODEL_PP002P64,
  MODEL_PP002P82,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE2, MODEL_PP002P82MIX, MODEL_PP002P82NOCE1, MODEL_PP002P82NOCE2,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO2, MODEL_PS001, MODEL_PS002, MODEL_PS003,
  MODEL_PS004,
  MODEL_PS005, MODEL_STILE,
  MODEL_VERTICALE, MODEL_VMS, MODEL_PB155, MODEL_PS004_2, MODEL_PB003, MODEL_VERTICALE_2,
  MODEL_VERTICALE_3, MODEL_VERTICALE_LM, MODEL_PP002P82_0, MODEL_PP002P82PINO1_0, MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1_0, MODEL_PP002P82COLORE2_0, MODEL_PP002P82NOCE1_0, MODEL_PP002P82NOCE2_0,
  MODEL_PP002P102_0, MODEL_TVORA5, MODEL_SOLAR_PANEL_POLE, MODEL_TABLE_SOLAR_PANEL, MODEL_CARPORT, MODEL_BALLAST,
  MODEL_PP002P64V,
  MODEL_EQ,
  MODEL_PP001P46_3,
  MODEL_PP001P46_2,
  MODEL_PB002,
  MODEL_TEST,
} from '../../ModelsConstants';

export const modelsWithSpan = [
  MODEL_PB001,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001W,
  MODEL_PP001N,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_PP001P46,
  MODEL_PP001P46_2,
  MODEL_PP001P46_3,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_PS005,
  MODEL_VERTICALE,
  MODEL_VERTICALE_LM,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PB155,
  MODEL_PB003,
];

export const modelsWithSingleLeafGate = [
  MODEL_PB001,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP001W,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_VERTICALE,
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_STILE,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_P302,
  MODEL_P302_PB002,
  MODEL_P302_PS005,
  MODEL_PB003,
  MODEL_PP001P46,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PB155,
  MODEL_TVORA5,
];

export const modelsWithFoldingGate = [
  MODEL_2D,
  MODEL_2DS,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PB001,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_P302,
  MODEL_P302_PB002,
  MODEL_P302_PS005,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_VERTICALE,
  MODEL_PB155,
  MODEL_PB003,
];

export const modelsWithSingleLeafFoldingGate = [
  MODEL_PP002P,
  MODEL_PP002,
  MODEL_PB001,
  MODEL_2DS,
  MODEL_2D,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_P302,
  MODEL_P302_PB002,
  MODEL_P302_PS005,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_VERTICALE,
  MODEL_PB155,
  MODEL_PB003,
];

export const modelsWithNonSymmetricalGate = [
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_STILE,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PB001,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP001W,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_PP001P46,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_P302,
  MODEL_P302_PB002,
  MODEL_P302_PS005,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_VERTICALE,
  MODEL_PB155,
  MODEL_PB003,
  MODEL_TVORA5,
];

export const modelsWithGateGateway = [
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_STILE,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PB001,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP001W,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_PP001P46,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P82,
  MODEL_PP002P82_0,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_P302,
  MODEL_P302_PS005,
  MODEL_P302_PB002,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_VERTICALE,
  MODEL_PB155,
  MODEL_PB003,
  MODEL_TVORA5,
];

export const modelsWithGatewayWithMachine = [
  MODEL_PS004,
];

export const modelsWithoutColor = [
  MODEL_PS005, MODEL_HIGHWAY, MODEL_CARPORT, MODEL_BALLAST,
  MODEL_SOLAR_PANEL_POLE, MODEL_TABLE_SOLAR_PANEL, MODEL_VERTICALE_2, MODEL_VERTICALE_3, MODEL_VERTICALE_LM, MODEL_VMS, MODEL_EQ, MODEL_PP001P46_3, MODEL_PP001P46_2,
];

const OrderFormFenceType = ({ form, updateForm }) => {
  const handleChange = (e) => {
    e.preventDefault();

    const color = (modelsWithoutColor.indexOf(form.model) > -1 ? null : form.color);

    // we need to clean up values and response, because it's different form
    updateForm({
      ...form,
      color,
      type: e.target.value,
      values: {},
      response: {},
    });
  };

  const spanOptions = () => (modelsWithSpan.indexOf(form.model) > -1 ? <option value="span">Przęsło</option> : null);

  const singleLeafGateOptions = () => (modelsWithSingleLeafGate.indexOf(form.model) > -1 ? <option value="singleLeafGate">Brama jednoskrzydłowa</option> : null);

  const foldingGateOptions = () => (modelsWithFoldingGate.indexOf(form.model) > -1 ? <option value="foldingGate">Brama łamana</option> : null);

  const singleLeafFoldingGateOptions = () => (modelsWithSingleLeafFoldingGate.indexOf(form.model) > -1 ? <option value="singleLeafFoldingGate">Brama łamana jednoskrzydłowa</option> : null);

  const gateGatewayOptions = () => (modelsWithGateGateway.indexOf(form.model) > -1 ? <option value="gateGateway">Bramo-furtka</option> : null);

  const nonSymmetricalGateOptions = () => (modelsWithNonSymmetricalGate.indexOf(form.model) > -1 ? <option value="nonSymmetricalGate">Brama niesymetryczna</option> : null);

  const gatewayWithMachineOptions = () => (modelsWithGatewayWithMachine.indexOf(form.model) > -1 ? <option value="gatewayWithMachine">Furtka z automatem</option> : null);

  const isVisible = form.productSingular && form.model;
  const className = isVisible ? 'form-group orderFormFenceType' : 'form-group orderFormFenceType orderFormFenceTypeHidden';
  if ((form.model === MODEL_2D || form.model === MODEL_2DS || form.model === MODEL_3D_4 || form.model === MODEL_3D_5 || form.model === MODEL_VMS || form.model === MODEL_ECO || form.model === MODEL_PP002) && !form.color) {
    return null;
  } if (form.model === MODEL_SOLAR_PANEL_POLE || form.model === MODEL_TABLE_SOLAR_PANEL || form.model === MODEL_EQ) {
    return null;
  } if (modelsWithoutColor.indexOf(form.model) === -1 && !form.color) {
    return null;
  } if (form.model === MODEL_PS005 || form.model === MODEL_PP001P46_3 || form.model === MODEL_PP001P46_2 || form.model === MODEL_PB002) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="span">Przęsło</option>
        </select>
      </div>
    );
  } if (form.model === MODEL_VMS || form.model === MODEL_ECO || form.model === MODEL_HIGHWAY) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="gateway">Furtka</option>
          <option value="gateTilting">Brama uchylna</option>
        </select>
      </div>
    );
  } if (form.model === MODEL_CARPORT) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="basePolycarbonate">Baza Poliwęglan</option>
          <option value="basePlate">Baza Płyta Trapezowa</option>
          <option value="baseSolarPanels">Baza Fotowoltaika</option>
          <option value="extensionPolycarbonate">Rozbudowa Poliwęglan</option>
          <option value="extensionPlate">Rozbudowa Płyta Trapezowa</option>
          <option value="extensionSolarPanels">Rozbudowa Fotowoltaika</option>
        </select>
      </div>
    );
  }
  if (form.model === MODEL_BALLAST) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value={FENCE_TYPE_BALLAST}>System balastowy</option>
          <option value={FENCE_TYPE_WINDCHEST}>Wiatrownica</option>
        </select>
      </div>
    );
  }
  if (form.model === MODEL_VERTICALE_2 || form.model === MODEL_VERTICALE_3) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="span">Przęsło</option>
        </select>
      </div>
    );
  }
  if (form.model === MODEL_VERTICALE_LM) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="gateway">Furtka</option>
          <option value="gatewayAccessories">Furtka - akcesoria</option>
          <option value="gateTilting">Brama dwuskrzydłowa</option>
          <option value="gateTiltingAccessories">Brama dwuskrzydłowa - akcesoria</option>
          <option value="gateSelfSupporting">Brama samonośna</option>
          <option value="span">Przęsło</option>
        </select>
      </div>
    );
  } if (form.model === MODEL_P302 || form.model === MODEL_P302_PB002 || form.model === MODEL_P302_PS005) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="gateway">Furtka</option>
          {gatewayWithMachineOptions()}
          {gateGatewayOptions()}
          {singleLeafGateOptions()}
          <option value="gateTilting">Brama uchylna</option>
          {foldingGateOptions()}
          {singleLeafFoldingGateOptions()}
          {nonSymmetricalGateOptions()}
          <option value="gateSelfSupporting">Brama samonośna</option>
          <option value="span">Płyta XPS</option>
        </select>
      </div>
    );
  } if (process.env.NODE_ENV !== 'production' && form.model === MODEL_TEST) {
    return (
      <div className={className}>
        <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceType__${form.id}`}
          name="fenceType"
          onChange={handleChange}
          value={form.type || ''}
          required
        >
          <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
          <option value="gateway">Furtka testowa PB001</option>
        </select>
      </div>
    );
  }

  return (
    <div className={className}>
      <label htmlFor={`orderFormFenceType__${form.id}`}>Wybierz rodzaj ogrodzenia</label>
      <select
        className="form-control"
        id={`orderFormFenceType__${form.id}`}
        name="fenceType"
        onChange={handleChange}
        value={form.type || ''}
        required
      >
        <option value="" disabled>Wybierz rodzaj ogrodzenia</option>
        <option value="gateway">Furtka</option>
        {gatewayWithMachineOptions()}
        {gateGatewayOptions()}
        {singleLeafGateOptions()}
        <option value="gateTilting">Brama uchylna</option>
        {foldingGateOptions()}
        {singleLeafFoldingGateOptions()}
        {nonSymmetricalGateOptions()}
        <option value="gateSelfSupporting">Brama samonośna</option>
        {spanOptions()}
      </select>
    </div>
  );
};

OrderFormFenceType.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormFenceType;
