import React from 'react';
import PropTypes from 'prop-types';

const GatewayResponse = ({ response }) => (
  <>
    <div>
      <p>
        Ilość pól:
        {' '}
        {response.productionMaterials.info.areaAmount.value}
        {' '}
        szt
      </p>
    </div>
    <div>
      <p>
        Wysokość pola [H]:
        {' '}
        {response.productionMaterials.info.areaHeight.value}
        {' '}
        mm
      </p>
    </div>
    <div>
      <p>
        Szerokość pola [L]:
        {' '}
        {response.productionMaterials.info.areaWidth.value}
        {' '}
        mm
      </p>
    </div>
    <div className="illustration">
      <div className="illustrationSquareGate" />
      <p className="illustrationHeightSymbolGate">H</p>
      <p className="illustrationWidthSymbolGate">L</p>
    </div>
  </>
);

GatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GatewayResponse;
