import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Poles, { POLES_LACK } from '../Input/Poles';
import RivetNutPole from '../Input/RivetNutPole';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import CoverBetweenWings from '../Input/CoverBetweenWings';
import Hinges from '../Input/Hinges';
import FormActions from '../Input/FormActions';
import { FENCE_TYPE_NON_SYMMETRICAL_GATE } from '../../FenceTypesConstants';
import NonSymmetricalGateResponse from './NonSymmetricalGateResponse';
import invoke from '../../lambda';
import { COLOR_HDG } from '../OrderForm/OrderFormFenceColor';

const NonSymmetricalGate = ({ form, updateForm }) => {
  const initialValues = {
    height: form.values.height || '',
    leftWingWidth: form.values.leftWingWidth || '',
    rightWingWidth: form.values.rightWingWidth || '',
    poles: form.values.poles || '',
    rivetNutPole: form.values.rivetNutPole || '',
    holder: form.values.holder || '',
    hinges: form.values.hinges || '',
    coverBetweenWings: form.values.coverBetweenWings || '',
  };

  const isResponseAvailable = Object.keys(form.response).length > 0;

  const polesOnChange = (e, handleChange, setFieldValue) => {
    handleChange(e);

    if (e.target.value === POLES_LACK) {
      setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const res = await invoke({
      amount: form.quantity,
      model: form.color === COLOR_HDG ? 'PANEL 3D(5) HDG' : 'PANEL 3D(5)',
      type: 'uchylna',
      color: form.color,
      assymetry: 'BRAMA NIESYMETRYCZNA',
      ...values,
    });

    const newForm = {
      ...form,
      values: {
        ...values,
      },
      response: {
        ...res.products[0],
      },
    };

    setSubmitting(false);
    updateForm(newForm);
  };

  const validation = ({ height, leftWingWidth, rightWingWidth }) => {
    const errors = {};

    if (!height) {
      errors.height = 'Wymagana wartość';
    } else if (height < 500) {
      errors.height = 'Minimalna wysokość to 500mm.';
    } else if (height > 2300) {
      errors.height = 'Maksymalna wysokość to 2300mm.';
    }
    if (!leftWingWidth) {
      errors.width = 'Wymagana wartość';
    } else if (leftWingWidth < 500) {
      errors.width = 'Minimalna szerokość to 500mm.';
    } else if (leftWingWidth > 5000) {
      errors.width = 'Maksymalna szerokość to 5000mm.';
    }
    if (!rightWingWidth) {
      errors.width = 'Wymagana wartość';
    } else if (rightWingWidth < 500) {
      errors.width = 'Minimalna szerokość to 500mm.';
    } else if (rightWingWidth > 5000) {
      errors.width = 'Maksymalna szerokość to 5000mm.';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validation}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="formInputs">
            <div className="form-group">
              <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`height__${form.id}`}
                name="height"
                value={values.height}
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <span className="error">{errors.height && errors.height}</span>
            </div>
            <div className="form-group">
              <label htmlFor={`leftWingWidth__${form.id}`}>Lewe skrzydło szerokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`leftWingWidth__${form.id}`}
                name="leftWingWidth"
                value={values.leftWingWidth}
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <span className="error">{errors.leftWingWidth && errors.leftWingWidth}</span>
            </div>
            <div className="form-group">
              <label htmlFor={`rightWingWidth__${form.id}`}>Prawe skrzydło szerokość (mm)</label>
              <input
                type="number"
                className="form-control"
                placeholder="Wpisz"
                id={`rightWingWidth__${form.id}`}
                name="rightWingWidth"
                value={values.rightWingWidth}
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <span className="error">{errors.rightWingWidth && errors.rightWingWidth}</span>
            </div>
            <Poles
              formId={form.id}
              onChange={(e) => polesOnChange(e, handleChange, setFieldValue)}
              fenceType={FENCE_TYPE_NON_SYMMETRICAL_GATE}
              value={values.poles}
            />
            {values.poles && values.poles !== POLES_LACK && (
              <RivetNutPole
                formId={form.id}
                onChange={handleChange}
                value={values.rivetNutPole}
              />
            )}
            <Holder
              formId={form.id}
              onChange={handleChange}
              poles={values.poles}
              value={values.holder}
            />
            <Hinges
              formId={form.id}
              onChange={handleChange}
              fenceType={FENCE_TYPE_NON_SYMMETRICAL_GATE}
              value={values.hinges}
            />
            <CoverBetweenWings
              formId={form.id}
              onChange={handleChange}
              value={values.coverBetweenWings}
            />
          </div>
          <FormActions isSubmitting={isSubmitting} />
          {form.response?.error && (
          <h2 style={{ color: 'red' }}>
            Wystąpił błąd:
            {' '}
            {form.response.error}
          </h2>
          )}
          {isResponseAvailable && !form.response?.error && <NonSymmetricalGateResponse values={form.values} hdg={form.color === COLOR_HDG} response={form.response} isPdf={false} />}
        </form>
      )}
    </Formik>
  );
};

NonSymmetricalGate.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default NonSymmetricalGate;
