import React from 'react';
import PropTypes from 'prop-types';
import { MOUNTING_STRIP_ZERO } from 'components/Input/MountingStrip';

export const PP002P820FoldingGateSecondPage = ({ form }) => (
  <>
    <table className="table">
      <thead>
        <tr>
          <th colSpan="7">GALANTERIA</th>
        </tr>
        <tr>
          <th className="responseThSingular">Lp.</th>
          <th className="responseThName">Nazwa</th>
          <th className="responseThMaterial">Materiał</th>
          <th className="responseThQuantity">Ilość</th>
          <th className="responseThMeasure">Wymiar</th>
          <th>{' '}</th>
          <th className="responseThSaws">GALANTERIA</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(form.response.productionMaterials.gallantry).map((key, index) => (
          <tr key={key}>
            <td>{index + 1}</td>
            <td>{form.response.productionMaterials.gallantry[key].name}</td>
            <td>{form.response.productionMaterials.gallantry[key].material}</td>
            <td>
              {form.response.productionMaterials.gallantry[key].amount.value}
              {' '}
              {form.response.productionMaterials.gallantry[key].amount.unit}
            </td>
            <td>
              {form.response.productionMaterials.gallantry[key].size.value}
              {' '}
              {form.response.productionMaterials.gallantry[key].size.unit}
            </td>
            <td />
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {(form.values.mountingStrip !== MOUNTING_STRIP_ZERO)
      && (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>OTWORY MONTAŻOWE W LISTWIE MONTAŻOWEJ</th>
              </tr>
            </thead>
          </table>
          <p>
            <b>{form.response.productionMaterials.mountingStrip.amountOfHoles.name}</b>
            {': '}
            {form.response.productionMaterials.mountingStrip.amountOfHoles.value}
            {' '}
            {form.response.productionMaterials.mountingStrip.amountOfHoles.unit}
            {' | '}
            <b>{form.response.productionMaterials.mountingStrip.spacingBetweenHoles.name}</b>
            {': '}
            {form.response.productionMaterials.mountingStrip.spacingBetweenHoles.value}
            {' '}
            {form.response.productionMaterials.mountingStrip.spacingBetweenHoles.unit}
          </p>
        </>
      )}
  </>
);

const FoldingGateResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">GALANTERIA</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.gallantry[key].name}</td>
                <td>{response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].size.unit}
                </td>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isPdf && (values.mountingStrip !== MOUNTING_STRIP_ZERO)
        && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>OTWORY MONTAŻOWE W LISTWIE MONTAŻOWEJ</th>
                </tr>
              </thead>
            </table>
            <p>
              <b>{response.productionMaterials.mountingStrip.amountOfHoles.name}</b>
              {': '}
              {response.productionMaterials.mountingStrip.amountOfHoles.value}
              {' '}
              {response.productionMaterials.mountingStrip.amountOfHoles.unit}
              {' | '}
              <b>{response.productionMaterials.mountingStrip.spacingBetweenHoles.name}</b>
              {': '}
              {response.productionMaterials.mountingStrip.spacingBetweenHoles.value}
              {' '}
              {response.productionMaterials.mountingStrip.spacingBetweenHoles.unit}
            </p>
          </>
        )}
    </div>
  );
};

FoldingGateResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default FoldingGateResponse;
