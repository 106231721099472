import React from 'react';

const Response = ({ Child, form, isPdf }) => (
  <>
    <div>
      {/* KĄTY */}
      {form.response.productionMaterials?.angles && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">KĄTY</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.angles).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.angles[key].name}</td>
                <td>{form.response.productionMaterials.angles[key].material}</td>
                <td>
                  {form.response.productionMaterials.angles[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.angles[key].amount.unit}
                </td>
                <td>
                  {form.response.productionMaterials.angles[key].size.value}
                  {' '}
                  {form.response.productionMaterials.angles[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* PIŁA */}
      {form.response.productionMaterials?.saw && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.saw).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.saw[key].name}</td>
                <td>{form.response.productionMaterials.saw[key].material}</td>
                <td>
                  {form.response.productionMaterials.saw[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.saw[key].amount.unit}
                </td>
                <td>
                  {form.response.productionMaterials.saw[key].size.value}
                  {' '}
                  {form.response.productionMaterials.saw[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* LASER */}
      {form.response.productionMaterials?.laser && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.laser).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.laser[key].name}</td>
                <td>{form.response.productionMaterials.laser[key].material}</td>
                <td>
                  {form.response.productionMaterials.laser[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.laser[key].amount.unit}
                </td>
                <td>
                  {form.response.productionMaterials.laser[key].size.value}
                  {' '}
                  {form.response.productionMaterials.laser[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* SPAWALNIA */}
      {form.response.productionMaterials?.welding && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={7}>SPAWALNIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <>
                <th>{' '}</th>
                <th className="responseThSaws">SPAWALNIA</th>
              </>
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.welding).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.welding[key].name}</td>
                <td>{form.response.productionMaterials.welding[key].material}</td>
                <td>
                  {form.response.productionMaterials.welding[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.welding[key].amount.unit}
                </td>
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* GALANTERIA */}
      {form.response.productionMaterials?.gallantry && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th className="responseThSaws">GALANTERIA</th>
                <th>{' '}</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.gallantry[key].name}</td>
                <td>{form.response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {form.response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {form.response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {form.response.productionMaterials.gallantry[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td />
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* BUFOR */}
      {form.response.productionMaterials?.buffer && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>BUFOR</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">BUFOR</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.buffer).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.buffer[key].name}</td>
                <td>{form.response.productionMaterials.buffer[key].material}</td>
                <td>
                  {form.response.productionMaterials.buffer[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.buffer[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* MAGAZYN */}
      {form.response.productionMaterials?.storage && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OSPRZĘT</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.storage).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.storage[key].name}</td>
                <td>{form.response.productionMaterials.storage[key].material}</td>
                <td>
                  {form.response.productionMaterials.storage[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.storage[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* MAGAZYN ZDEJMOWANIE */}
      {form.response.productionMaterials?.storageRemoval && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - ZDEJMOWANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">ZDEJMOWANIE</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.storageRemoval).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.storageRemoval[key].name}</td>
                <td>{form.response.productionMaterials.storageRemoval[key].material}</td>
                <td>
                  {form.response.productionMaterials.storageRemoval[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.storageRemoval[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* MAGAZYN OWIJARKA */}
      {form.response.productionMaterials?.storageWrapper && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OWIJARKA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OWIJARKA</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.storageWrapper).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.storageWrapper[key].name}</td>
                <td>{form.response.productionMaterials.storageWrapper[key].material}</td>
                <td>
                  {form.response.productionMaterials.storageWrapper[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.storageWrapper[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* NABIJANIE */}
      {form.response.productionMaterials?.studding && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>NABIJANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">NABIJANIE</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(form.response.productionMaterials.studding).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{form.response.productionMaterials.studding[key].name}</td>
                <td>{form.response.productionMaterials.studding[key].material}</td>
                <td>
                  {form.response.productionMaterials.studding[key].amount.value}
                  {' '}
                  {form.response.productionMaterials.studding[key].amount.unit}
                </td>
                <td>
                  {form.response.productionMaterials.studding[key].size.value}
                  {' '}
                  {form.response.productionMaterials.studding[key].size.value === '' ? null : form.response.productionMaterials.studding[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Child form={form} response={form.response} isPdf={isPdf} />
    </div>
  </>
);

export default Response;
