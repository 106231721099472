import React from 'react';
import PropTypes from 'prop-types';

const BallastResponse = ({ response, isPdf }) => (
  <div>
    <table className="table">
      <thead>
        <tr>
          <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
        </tr>
        <tr>
          <th className="responseThSingular">Lp.</th>
          <th className="responseThName">Nazwa</th>
          <th className="responseThMaterial">Materiał</th>
          <th className="responseThQuantity">Ilość</th>
          <th className="responseThMeasure">Wymiar</th>
          {isPdf && (
          <>
            <th>{' '}</th>
            <th className="responseThSaws">GALANTERIA</th>
          </>
          )}
        </tr>
      </thead>
      <tbody>
        {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
          <tr key={key}>
            <td>{index + 1}</td>
            <td>{response.productionMaterials.gallantry[key].name}</td>
            <td>{response.productionMaterials.gallantry[key].material}</td>
            <td>
              {response.productionMaterials.gallantry[key].amount.value}
              {' '}
              {response.productionMaterials.gallantry[key].amount.unit}
            </td>
            <td>
              {response.productionMaterials.gallantry[key].size.value}
              {' '}
              {response.productionMaterials.gallantry[key].size.unit}
            </td>
            <td />
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

BallastResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default BallastResponse;
